console.time('PortalLaunchTime');

const DEFAULT_APPLICATIONS = [
  'moment',
  'single-spa',
  'vue',
  'vue-router',
  'lodash',
  '@plt/styleguide',
  '@plt/header',
  '@common/navbar',
];

const CONFIG_MODULE_NAME_DEFAULT = '@plt/root-config';

const getMomentModule = (loadedModules, applicationList) => {
  const momentPosition = applicationList.indexOf('moment');
  if (momentPosition >= 0) {
    return loadedModules[momentPosition].default;
  }
  return undefined;
};

const emitEventMenuMode = () => {
  window.dispatchEvent(
    new CustomEvent('changePortalView', {
      detail: {
        expanded: window.localStorage.getItem('isExpanded') === 'true',
      },
    }),
  );
};

const emitDarkThemeMode = () => {
  let theme;

  if (localStorage.getItem('portalTheme') !== null) {
    theme = window.localStorage.getItem('portalTheme');
  } else {
     theme = 'default';
  }

  window.dispatchEvent(
    new CustomEvent('togglePortalTheme', {
      detail: { theme },
    }),
  );
};

let config = null;

console.time('PortalMainLoadTime');

if (!System) {
  console.error('SystemJS is undefined!');
  const node = document.createElement('div');
  node.innerHTML = `<h2 style="color: red; text-align: center">SystemJS is undefined!</h2>`;
  document.body.appendChild(node);
} else {
  System.import('config')
    .then((module) => {
      config = module.default;
    })
    .then(() => {
      System.import('lodash');
    })
    .catch((e) => {
      console.error('Error by root config init:', e);
    })
    .finally(() => {
      let rootConfigModule;
      const rootConfigModuleName = config?.configModuleName || CONFIG_MODULE_NAME_DEFAULT;
      System.import(rootConfigModuleName)
        .then((module) => {
          rootConfigModule = module;
          return rootConfigModule.prepareServerDataBeforeStart({ config });
        })
        .then((response) => {
          const {
            redirect,
            user,
            serverTime,
            routes,
            casConfig,
            accessInfo,
          } = response || {};
          if (redirect) {
            try {
              return redirect();
            } catch (e) {
              console.error('Error by redirect:', e);
            }
          }
          const startLoadingApplication = config?.startLoadingApplication || DEFAULT_APPLICATIONS;
          Promise.all(
            startLoadingApplication
              .filter((o) => o !== 'lodash')
              .map((o) => System.import(o)),
          )
            .then((loadedApplication) => {
              window.moment = getMomentModule(
                loadedApplication,
                startLoadingApplication,
              );
            })
            .then(() => {
              console.timeEnd('PortalMainLoadTime');
              rootConfigModule.startPortal({
                config,
                user,
                serverTime,
                routes,
                accessInfo,
                casConfig,
              });
              emitEventMenuMode();
              if (config.hasDarkTheme) {
                emitDarkThemeMode();
              }
            })
            .then(() => {
              console.time('PortalBackgroundsLoadTime');
              const startLoadingOptionalApplication = config?.startLoadingOptionalApplication || [];
              return new Promise((resolve) => {
                setTimeout(
                  () => {
                    Promise.allSettled(
                      startLoadingOptionalApplication.map((o) => System.import(o)),
                    ).finally(() => {
                      resolve();
                    });
                  },
                  // отложив импорт фоновых приложений, разгрузим сеть для более быстрого старта портала
                  1000,
                );
              });
            })
            .then(() => {
              console.timeEnd('PortalBackgroundsLoadTime');
            })
            .catch((e) => {
              console.error('Error by loading apps:', e);
            });
        })
        .catch((e) => {
          console.error('Error by portal init:', e);
        });
    });
}
